import React from "react";

interface ButtonType {
  text: string;
  className?: string;
  onClick?:() => void;
  disabled?: boolean;
}

const Button = ({ text, className, onClick, disabled }: ButtonType) => {
  return (
    <div>
      <button
      disabled={disabled}
        onClick={onClick}
        className={`py-3 BBody18 rounded-[10px] disabled:border-none disabled:bg-[#4383FF]/20 border border-[#4383FF] cursor-pointer ${className}`}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;