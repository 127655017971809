import React from "react";

import SearchBox from "components/SearchBox";
import { useNavigate } from "react-router-dom";
import ArtistInfoCard from "components/ArtistInfoCard";
import ContentWrap from "components/ContentWrap";

const ArtistList = () => {
  const navigate = useNavigate();
  return (
    <div className="!pb-6">
      <ContentWrap className="flex items-center justify-between">
        <div />
        <SearchBox
          className="w-[590px]"
          placeholder="아티스트 이름, 작품, 소속사, 학교 등을 입력해 주세요"
        />
        <div>필터</div>
      </ContentWrap>
      <ContentWrap className="flex flex-wrap gap-x-5 gap-y-10 pt-10">
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
        <ArtistInfoCard
          onClick={() => {
            navigate("3");
          }}
          artistName={"강소라"}
          artistAge={"1997년생"}
          artistSize="173cm 80kg"
        />
      </ContentWrap>
    </div>
  );
};

export default ArtistList;
