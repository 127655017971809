import React from "react";
import FooterLogo from "../assets/footerLogo.png";
import Insta from "../assets/instagram.png";
import Kakao from "../assets/kakao.png";
import Blog from "../assets/blog.png";
import Youtube from "../assets/youtube.png";
import ContentWrap from "./ContentWrap";

const Footer = () => {
  const Sns = [
    { title: "youtube", src: Youtube },
    { title: "blog", src: Blog },
    { title: "insta", src: Insta },
    { title: "kakao", src: Kakao },
  ];

  const Info = [
    {
      title: "대표",
      content: "유성봉",
      needGap: true,
    },
    {
      title: "사업자등록번호",
      content: "772-87-03229",
      needGap: true,
    },
    {
      title: "통신판매업 신고번호",
      content: "2024-서울강남-02551호",
      needGap: true,
    },
    {
      title: "고객센터",
      content: "02-517-2468",
      needGap: true,
    },
    {
      title: "개인정보정책 및 담당",
      content: "유성봉",
      needGap: true,
    },
    {
      title: "광고 및 제휴문의",
      content: "support@castingvote.com",
      needGap: true,
    },
  ];

  return (
    <div className="w-full relative bottom-0 flex flex-col px-10 bg-[#333]">
      <ContentWrap className="py-12 flex flex-col gap-24">
        <div className="flex justify-between">
          <img src={FooterLogo} alt="로고" />
          <div className="flex gap-6">
            {Sns.map((item) => {
              return (
                <img
                  key={item.title}
                  className="w-7 h-7"
                  src={item.src}
                  alt={item.title}
                />
              );
            })}
          </div>
        </div>
        <div className="text-white RBody16 gap-0.5">
          <div className="flex gap-2">
            <div className="font-bold">대표</div>
            <div>유성봉</div>
          </div>
          <div>
            <div className="flex gap-4">
              <div className="flex gap-2">
                <div className="font-bold">사업자등록번호</div>
                <div>772-87-03229</div>
              </div>
              <div className="flex gap-2">
                <div className="font-bold">통신판매업 신고번호</div>
                <div>2024-서울강남-02551호</div>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="font-bold">주소</div>
            <div>서울 강남구 영동대로 701 W타워 8층 (청담동)</div>
          </div>
          <div className="flex gap-4">
            <div className="flex gap-2">
              <div className="font-bold">고객센터</div>
              <div>02-517-2468</div>
            </div>
            <div className="flex gap-2">
              <div className="font-bold">개인정보정책 및 담당</div>
              <div>유성봉</div>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="font-bold">광고 및 제휴문의</div>
            <div>support@castingvote.com</div>
          </div>
        </div>
      </ContentWrap>
      <hr className="text-white opacity-10" />
      <ContentWrap className="!py-6 flex desktop:justify-between">
        <div className="text-white RBody16 opacity-30 w-[454px]">
          Copyright ⓒ CASTING VOTE ALL RIGHTS RESERVED.
        </div>
        <div className="flex gap-6 text-white BBody16">
          <div>회사소개</div>
          <div>공지사항</div>
          <div>개인정보처리방침</div>
          <div>이용약관</div>
          <div>광고문의</div>
        </div>
      </ContentWrap>
    </div>
  );
};

export default Footer;
