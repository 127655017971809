import React, { useState } from "react";

import KakaoLogin from "../assets/kakaoLogin.png";

import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import Checkbox from "components/Checkbox";

const Login = () => {
  const navigate = useNavigate();
  const [agreeTermModal, setAgreeTermModal] = useState(false);

  return (
    <div className="py-20 mx-auto flex flex-col max-w-[520px]  justify-between gap-10">
      {agreeTermModal && (
        <Modal onClose={() => setAgreeTermModal(false)}>
          <div className="p-4">
            <Button
              onClick={() => navigate("/signup")}
              className="w-[348px] BBody16 text-white bg-[#4383FF]"
              text="동의하고 계속하기"
            />
          </div>
        </Modal>
      )}
      <div
        className="text-center text-2xl font-bold"
      >
        캐스팅을 향한 빠른 길,
        <br />
        캐스팅보트를 시작하세요 🙌
      </div>
      <div className="flex flex-col mx-auto gap-4">
        <Input
          placeholder="아이디를 입력해 주세요"
          className=" w-[520px]"
          type="text"
        />
        <Input
          placeholder="비밀번호를 입력해 주세요"
          className=" w-[520px]"
          type="password"
        />
        <Checkbox className="RBody14 text-[#898A8D]" title="로그인 유지"/>
      </div>
      <div className="flex mx-auto text-center flex-col gap-4 max-w-[520px]">
        <Button
          onClick={() => {
            setAgreeTermModal(true);
          }}
          className="w-[520px] text-[#4383FF]"
          text="이메일로 가입"
        />
        <Button className="w-[520px] text-white bg-[#4383FF]" text="로그인" />
        <div className="flex items-center mx-auto gap-4 RBody14 text-[#898A8D]">
          <div>아이디 찾기</div>
          <div>|</div>
          <div>비밀번호 찾기</div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between mx-auto gap-[41px] ">
          <hr className="w-[171px] h-[1px] bg-[#D9D9D9]" />
          <div className="text-sm MBody14 text-[#898A8D]">SNS 간편 로그인</div>
          <hr className="w-[171px] h-[1px] bg-[#D9D9D9]" />
        </div>
        <div className="text-sm RBody14 cursor-pointer grid gap-2 text-[#171717] items-center mx-auto">
          <img src={KakaoLogin} alt="snsLogin" />
          <div>카카오톡</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
