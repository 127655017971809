import React from "react";



const CastingCard = () => {
  return (
    <div>
      <div className="w-56 cursor-pointer h-[310px] border border-black rounded-[10px]" />
    </div>
  );
};

export default CastingCard;
