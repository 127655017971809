import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Layout from "./components/Layout";
import Login from "pages/Login";
import Signup from "pages/Signup";
import ArtistList from "pages/ArtistList";
import ArtistDetail from "pages/ArtistDetail";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/artist" element={<ArtistList />} />
        <Route path='/artist/:artistId' element={<ArtistDetail />} />
      </Route>
    </Routes>
  );
}

export default App;
