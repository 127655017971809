import React from "react";

interface ArtistInfoCardType {
  artistName: string;
  artistAge: string;
  artistSize?: string;
  onClick?: () => void;
}

const ArtistInfoCard = ({
  artistName,
  artistAge,
  artistSize,
  onClick,
}: ArtistInfoCardType) => {
  return (
    <div>
      <div
        onClick={onClick}
        className="w-[285px] cursor-pointer h-[357px] border border-black rounded-[10px]"
      />
      <div className="flex gap-2 mt-4 mb-2 items-center">
        <div className="BBody20">{artistName}</div>
        <div className="MBody16 text-[#898A8D]">{artistAge}</div>
      </div>
      {artistSize && <div className="MBody16 text-[#898A8D]">{artistSize}</div>}
    </div>
  );
};

export default ArtistInfoCard;
