import Button from "components/Button";
import Input from "components/Input";
import Radio from "components/Radio";
import TitleWrap from "components/TitleWrap";
import React, { useState } from "react";

const Signup = () => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const totalStep = 4;

  const staffJop = [
    {title: "캐스팅 디렉터", id: 'castingDirector', name:'jop'},
    {title: "제작자", id: 'producer', name:'jop'},
    {title: "연출자", id: 'director', name:'jop'},
    {title: "매니저", id: 'manager', name:'jop'},
  ]

  const selectType = (type: string) => {
    if (selectedType === type) {
      setSelectedType("");
    } else {
      setSelectedType(type);
    }
  };

  const getDots = () => {
    const dots = [];
    for (let i = 1; i <= totalStep; i++) {
      dots.push(i <= currentStep ? "blue" : "gray");
    }
    return dots;
  };

  const selectGender = (Gender: string) => {
    if (selectedGender === Gender) {
      setSelectedGender("");
    } else {
      setSelectedGender(Gender);
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="py-20 mx-auto flex flex-col max-w-[520px] justify-between gap-10">
      <div className="flex items-center gap-2">
        {getDots().map((color, i) => {
          return (
            <div
              key={i}
              className={`w-2 h-2 rounded-full ${
                color === "gray" ? "bg-[#D9D9D9]" : "bg-[#4383FF]"
              }`}
            />
          );
        })}
        <div className="w-5 h-6">🏃‍♀️‍</div>
      </div>

      {currentStep === 1 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            시작하기 전에
            <br />
            직업을 선택해 주세요
          </div>
          <div className="flex flex-col gap-4 mt-10 mb-20">
            <Button
              onClick={() => {
                selectType("artist");
              }}
              className={`w-[520px] ${
                selectedType === "artist"
                  ? "text-[#4383FF]"
                  : "text-[#898A8D] border-[#898A8D]"
              }`}
              text={"아티스트"}
            ></Button>
            <Button
              onClick={() => {
                selectType("staff");
              }}
              className={`w-[520px] ${
                selectedType === "staff"
                  ? "text-[#4383FF]"
                  : "text-[#898A8D] border-[#898A8D]"
              }`}
              text={"Staff (캐스팅 디렉터, 감독, 프로듀서 등등)"}
            ></Button>
          </div>
          <Button
            onClick={() => {
              handleNextStep();
            }}
            disabled={!selectedType}
            className={`w-[520px] bg-[#4383FF] text-white`}
            text={"다음"}
          ></Button>
        </div>
      )}
      {currentStep === 2 && selectedType === "artist" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>

          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="이메일">
              <Input
                className="w-[520px]"
                placeholder="이메일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="이름">
              <Input
                className="w-[520px]"
                placeholder="이름을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="생년월일">
              <Input
                className="w-[520px]"
                placeholder="생년월일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="성별">
              <div className="flex items-center justify-between">
                <Button
                  onClick={() => {
                    selectGender("female");
                  }}
                  className={`w-[250px] ${
                    selectedGender === "female"
                      ? "text-[#4383FF]"
                      : "text-[#898A8D] border-[#898A8D]"
                  }`}
                  text="여성"
                ></Button>
                <Button
                  onClick={() => {
                    selectGender("male");
                  }}
                  className={`w-[250px] ${
                    selectedGender === "male"
                      ? "text-[#4383FF]"
                      : "text-[#898A8D] border-[#898A8D]"
                  }`}
                  text="남성"
                ></Button>
              </div>
            </TitleWrap>
          </div>
          <div className="flex items-center justify-between">
            <Button
              onClick={() => {
                handlePrevStep();
              }}
              className={`w-[250px] border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                handleNextStep();
              }}
              className={`w-[250px] bg-[#4383FF] text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 2 && selectedType === "staff" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>
          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="직업">
              <div className="flex items-center gap-5">
                {staffJop.map((item, i) => {
                  return (
                    <Radio key={item.id} id={item.id} name={item.name} title={item.title}/>
                  )
                })}
                </div>
            </TitleWrap>
            <TitleWrap title="이메일">
              <Input
                className="w-[520px]"
                placeholder="이메일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="이름">
              <Input
                className="w-[520px]"
                placeholder="이름을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="생년월일">
              <Input
                className="w-[520px]"
                placeholder="생년월일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                className="w-[520px]"
                placeholder="소속회사를 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="사업자등록증">
              <Button
                className={`w-[520px] !BBody16 bg-[#4383FF]/20 border-none text-[#4383FF]`}
                text={"사업자 등록증 업로드"}
              ></Button>
            </TitleWrap>
          </div>
          <div className="flex items-center justify-between">
            <Button
              onClick={() => {
                handlePrevStep();
              }}
              className={`w-[250px] border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                handleNextStep();
              }}
              className={`w-[250px] bg-[#4383FF] text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            거의 다 왔어요!
            <br />
            휴대폰 인증을 시작할게요
          </div>

          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="휴대폰번호">
              <div className="flex items-center gap-2">
                <Input
                  className="w-[424px]"
                  placeholder="-없이 숫자만 입력해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  className="w-[88px] text-[#4383FF] !MBody16"
                  text="인증요청"
                ></Button>
              </div>
            </TitleWrap>
            <TitleWrap title="휴대폰번호 인증">
              <div className="flex items-center gap-2">
                <Input
                  className="w-[424px]"
                  placeholder="인증번호를 입력해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  className="w-[88px] text-[#898A8D] border !MBody16 border-[#D9D9D9]"
                  text="인증확인"
                ></Button>
              </div>
            </TitleWrap>
          </div>
          <div className="flex items-center justify-between">
            <Button
              onClick={() => {
                handlePrevStep();
              }}
              className={`w-[250px] border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                handleNextStep();
              }}
              className={`w-[250px] bg-[#4383FF] text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 4 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            아이디와 비밀번호를
            <br />
            입력해 주세요
          </div>
          <div className="flex flex-col BBody18 gap-10 mt-10 mb-20">
            <div className="flex flex-col gap-4">
              <div>아이디</div>
              <Input
                className="w-[520px]"
                placeholder="아이디를 6자 이상으로 설정해 주세요"
                type={"text"}
              ></Input>
            </div>
            <div className="flex flex-col gap-4">
              <div>비밀번호</div>
              <Input
                className="w-[520px]"
                placeholder="알파벳, 숫자, 특수문자 포함 8자 이상으로 설정해 주세요"
                type={"password"}
              ></Input>
            </div>
            <div className="flex flex-col gap-4">
              <div>비밀번호 확인</div>
              <Input
                className="w-[520px]"
                placeholder="동일한 비밀번호를 입력해 주세요"
                type={"password"}
              ></Input>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Button
              onClick={() => {
                handlePrevStep();
              }}
              className={`w-[250px] border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              className={`w-[250px] bg-[#4383FF] text-white`}
              text={"완료"}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
