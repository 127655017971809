import React from "react";

interface InputType {
  placeholder?: string;
  type: string;
  className?: string;
}

const Input = ({ type, placeholder, className }: InputType) => {
  return (
    <div>
      <input
        type={type}
        placeholder={placeholder}
        className={`border RBody16 border-[#D9D9D9] rounded-2xl py-3 px-4 ${className}`}
      />
    </div>
  );
};

export default Input;
