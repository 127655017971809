import React from "react";
import Logo from "../assets/logo.png.png";
import Noti from "../assets/notification_icon.png";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "./Button";
import ContentWrap from "./ContentWrap";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="border-b border-[#f5f5f5/90]">
      <ContentWrap className="flex justify-between !py-4">
        <div className="flex items-center">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="w-[76px] h-7 mr-6 cursor-pointer"
            src={Logo}
            alt="로고"
          />
          <div className="flex gap-6 MBody18">
            <NavLink
              className={({ isActive }) => (isActive ? "text-[#4383FF]" : "")}
              to={"/casting"}
            >
              캐스팅
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "text-[#4383FF]" : "")}
              to={"/artist"}
            >
              아티스트
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "text-[#4383FF]" : "")}
              to={"/staff"}
            >
              STAFF
            </NavLink>
          </div>
        </div>
        <div className="flex items-center gap-6">
          <img src={Noti} alt="로고" />
          <Button
            text="로그인"
            className="px-4 !py-2 !BBody16 text-[#4383FF] bg-[#4383ff]/20 border-none"
            onClick={() => {
              navigate("/login");
            }}
          ></Button>
        </div>
      </ContentWrap>
    </div>
  );
};

export default Header;
