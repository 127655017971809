import { computeHeadingLevel } from '@testing-library/react';
import React from 'react';

interface TitleWrapType {
    title: string;
    children: React.ReactNode;
}

const TitleWrap = ({title, children}:TitleWrapType) => {
    return (
        <div className="flex flex-col gap-4">
        <div className='BBody18'>{title}</div>
        {children}
      </div>
    );
};

export default TitleWrap;