import React from 'react';

interface ContentWrapType {
    className ?:string;
    children?: React.ReactNode
}

const ContentWrap = ({children, className}:ContentWrapType) => {
    return (
        <div className={`px-10 pt-10 desktop:max-w-[1280px] w-full mx-auto ${className}`}>
            {children}
        </div>
    );
};

export default ContentWrap;