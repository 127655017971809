import React from 'react';

interface ArtistCardType {
  artistName: string;
  artistJop: string;
  artistSize?: string;
  onClick?:() => void;
}

const ArtistCard = ({artistName, artistJop, artistSize, onClick}:ArtistCardType) => {
    return (
        <div>
            <div onClick={onClick} className="w-[285px] cursor-pointer h-[285px] border border-black rounded-[10px]" />
            <div className="flex gap-2 mt-4 mb-2 items-center">
              <div className="BBody20">{artistName}</div>
              <div className="BBody16 text-[#898A8D]">{artistJop}</div>
            </div>
            {artistSize &&
            <div className='MBody16 text-[#898A8D]'>
              170/54
            </div>
            }
          </div>
    );
};

export default ArtistCard;