import CastingCard from "components/CastingCard";
import MainWrap from "components/MainWrap";
import ArtistCard from "components/ArtistCard";
import React from "react";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import ContentWrap from "components/ContentWrap";

const Main = () => {
  const navigate = useNavigate();

  const opt = {
    width: "590px",
    height: "279px",
    playerVars: {
      autoplay: 0, //자동 재생 여부
      modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
    },
  };

  return (
    <div className="">
      <div className="mt-10">
      <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={24}
      slidesPerView={1}
      navigation
      onSwiper={(swiper:any) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
     <SwiperSlide>
        <div className="w-full max-w-[1280px] h-[600px] border mx-auto border-black rounded-[10px]"></div>
     </SwiperSlide>
     <SwiperSlide>
        <div className="w-full max-w-[1280px] h-[600px] border mx-auto border-black rounded-[10px]"></div>
     </SwiperSlide>
     <SwiperSlide>
        <div className="w-full max-w-[1280px] h-[600px] border mx-auto border-black rounded-[10px]"></div>
     </SwiperSlide>
     <SwiperSlide>
        <div className="w-full max-w-[1280px] h-[600px] border mx-auto border-black rounded-[10px]"></div>
     </SwiperSlide>
    </Swiper>
      </div>

      <ContentWrap>
        <MainWrap more={true} title="진행중인 캐스팅">
          <CastingCard />
        </MainWrap>
        <MainWrap more={false} title="지금 뜨고있는 프로필">
          <ArtistCard artistName={"이주빈"} artistJop={"배우"} />
        </MainWrap>
        <MainWrap
          onClick={() => {
            navigate("/artist");
          }}
          more={true}
          title="새로 올라온 프로필"
        >
          <ArtistCard artistName={"노제"} artistJop={"댄서"} />
        </MainWrap>
        <MainWrap more={false} title="캐스팅보트 핫클립">
          <YouTube
            className={"border w-[590px] rounded-2xl"}
            opts={opt}
            videoId="2g811Eo7K8U"
          />
        </MainWrap>
      </ContentWrap>
    </div>
  );
};

export default Main;
