import React from "react";

interface CheckBoxType {
    title: string;
    className?: string;
}

const Checkbox = ({title, className}:CheckBoxType) => {
  return (
    <div className="flex items-center mb-4">
      <input checked id="green-checkbox" type="checkbox" value="" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
      <label
        htmlFor="default-checkbox"
        className={`nonDragable ext-sm font-medium text-gray-900 dark:text-gray-300 ms-2 ${className}`}
      >
        {title}
      </label>
    </div>
  );
};

export default Checkbox;
