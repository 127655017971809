import React from "react";

import Search from "../assets/search.png";

interface SearchBoxType {
  placeholder: string;
  className?: string;
}

const SearchBox = ({ placeholder, className }: SearchBoxType) => {
  return (
    <div className="relative inline-block MBody14">
      <input
        placeholder={placeholder}
        className={`border rounded-full py-3 pl-4 pr-16 border-[#D9D9D9] ${className}`}
      />
      <img
        src={Search}
        alt="search"
        className="absolute right-4 top-2.5 w-7 h-7 "
      />
    </div>
  );
};

export default SearchBox;
