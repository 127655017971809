import React from "react";

interface MainWrapType {
  title: string;
  more: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const MainWrap = ({ title, children, more, onClick }: MainWrapType) => {
  return (
    <div className="my-[100px]">
      <div className="BHead36">{title}</div>
      <div className="pt-4">{children}</div>
      {more && (
        <div onClick={onClick} className="mt-4 text-right cursor-pointer">
          더보기
        </div>
      )}
    </div>
  );
};

export default MainWrap;
