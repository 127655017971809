import React from 'react';

interface RadioType {
    name: string;
    title: string;
    id  : string;
}

const Radio = ({name, title, id}:RadioType) => {
    return (
        <div className="flex items-center me-2">
            <input id={id} type="radio" name={name} value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            <label htmlFor={id} className="ms-2 RBody16 text-gray-900 dark:text-gray-300">{title}</label>
        </div>
    );
};

export default Radio;