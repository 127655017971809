import useOutSideClick from "hooks/onOutSideClick";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface ModalType {
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

function ModalContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("modal")!);
}

const Modal = ({ className, children, onClose }: ModalType) => {
  const modalRef = useRef(null);

  //     useEffect(() => {
  //     const $body = document.querySelector("body")!;
  //     const overflow = $body.style.overflow;
  //     $body.style.overflow = "hidden";
  //     return () => {
  //       $body.style.overflow = overflow;
  //     };
  //   }, []);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(modalRef, handleClose);

  return (
    <ModalContainer>
      <div className="fixed w-full h-full top-0 left-0 bg-black/45 z-[9999] text-[10px] font-normal leading-[14px]">
        <div ref={modalRef} className={`rounded-xl bg-white absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 ${className}`}
        >
        {children}
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
