import React from "react";


const ArtistDetail = () => {
  return (
    <div className="p-10 !pb-20 desktop:max-w-[1280px] mx-auto flex items-start gap-16">
      <div>이미지</div>
      <div>설명</div>
    </div>
  );
};

export default ArtistDetail;
